
var gApiFixmaster = function() {

  var fm = this;

  fm._prefix = '/api/fixmaster/';

  fm._endpoints = {
    getTile: fm._prefix + 'tile',
    getTiles: fm._prefix + 'tiles',
    getColours: fm._prefix + 'colours',
    getTown: fm._prefix + 'town',
    searchTowns: fm._prefix + 'search',
    getUnderlays: fm._prefix + 'underlays',
    getCompanyTypes: fm._prefix + 'company-types',
    getCounterbattens: fm._prefix + 'counterbattens',
    destroyCalculation: fm._prefix + 'destroy'
  };

  fm.getTile = function(name, colour) {
    return new Promise((resolve, reject) =>
      axios.post(fm._endpoints.getTile, {
        name: name,
        colour: colour,
      })
      .then(function (response) {
        resolve(response.data.tile);
      })
      .catch(function (error) {
        reject(error);
      })
    );
  }

  fm.getTiles = function() {
    return new Promise((resolve, reject) =>
      axios.get(fm._endpoints.getTiles)
      .then(function (response) {
        resolve(response.data.tiles);
      })
      .catch(function (error) {
        reject(error);
      })
    );
  }

  fm.getColours = function(name) {
    return new Promise((resolve, reject) =>
      axios.post(fm._endpoints.getColours, {
        name: name,
      })
      .then(function (response) {
        resolve(response.data.colours);
      })
      .catch(function (error) {
        reject(error);
      })
    );
  }

  fm.getTown = function(town_id) {
    return new Promise((resolve, reject) =>
      axios.post(fm._endpoints.getTown, {
        id: town_id,
      })
      .then(function (response) {
        resolve(response.data.town);
      })
      .catch(function (error) {
        reject(error);
      })
    );
  }

  fm.getCompanyTypes = function(town_id) {
    return new Promise((resolve, reject) =>
      axios.get(fm._endpoints.getCompanyTypes)
      .then(function (response) {
        resolve(response.data.companyTypes);
      })
      .catch(function (error) {
        reject(error);
      })
    );
  }

  fm.searchTowns = function(name) {
    return new Promise((resolve, reject) =>
      axios.post(fm._endpoints.searchTowns, {
        name: name,
      })
      .then(function (response) {
        resolve(response.data.towns);
      })
      .catch(function (error) {
        reject(error);
      })
    );
  }

  fm.getUnderlays = function() {
    return new Promise((resolve, reject) =>
      axios.get(fm._endpoints.getUnderlays)
      .then(function (response) {
        resolve(response.data.underlays);
      })
      .catch(function (error) {
        reject(error);
      })
    );
  }

  fm.getCounterbattens = function() {
    return new Promise((resolve, reject) =>
      axios.get(fm._endpoints.getCounterbattens)
      .then(function (response) {
        resolve(response.data.counterbattens);
      })
      .catch(function (error) {
        reject(error);
      })
    );
  }

  fm.destroyCalculation = function(id) {
    return new Promise((resolve, reject) =>
      axios.post(fm._endpoints.destroyCalculation, {
        id: id,
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      })
    );
  }

};
